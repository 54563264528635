.re_dialog_group_header{
    font-size: 2.8rem;
    line-height: 1.2em;
    font-weight: bold;
    text-align: center;
}

.re_dialog_input_text_corp_create{
    width: 90%;
    font-size: 2.5rem !important;
}