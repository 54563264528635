// .re_Portfolio_Dialog_generalIcon::before {
//     mask-image: url('../../../../img/element-icons/settings.svg');
// }

.re_dialog_group_container {
    padding: 0 15px;
}

.re_dialog_container {
    h2 {
        font-size: 2.2rem;
        line-height: 1.2em;
        font-weight: bold;
    }
}

.re_dialog_label_general {
    margin: 15px 0;
    font-size: 1.8rem;
    line-height: 1.2em;
    font-weight: bold;
    min-width: 120px;
}

.re_dialog_label {
    display: block;
}

.re_dialog_label_group {
    display: inline-block;
}

.re_dialog_input_text {
    font-size: 1.8rem;
    margin: 0 15px;
}

.re_dialog_input_profile {
    max-width: 300px;
}

// table style for portfolio

.re_table_style {
    width: 100%;
}

.re_content_portfolio {
    display: flex;
    margin: 22px 0;

    div {
        margin: 0 10px;
    }
}

.re_portfolio_date {
    border: 1px solid;
    padding: 5px;

    .re_during_content {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.re_button_add {
    background: #6eff0066;
    width: 250px;
    padding: 5px;
    text-align: center;
    margin: 10px 0;
    border-radius: 5px;
    cursor: pointer;
}

// validation inform

.re_no_valid_message {
    margin: 5px 10px;
    padding: 5px;
    background: rgba(233, 8, 8, 0.7);
    color: white;
    width: max-content;
    display: none;
}

.re_visible_no_valid_message {
    display: block;
}

// Style for upload dialog
// Style for message container

.re_upload_error_message {
    font-size: 2.5rem;
    line-height: 1.2em;
}

.re_upload_connect_message {
    color: black;
    font-weight: bold;
    line-height: 1.2em;
    font-size: 2rem;
}

.re_moderator_conect_container {
    display: flex;
    flex-wrap: wrap;
}

// .re_moderator_conect_button {
//     border: 1px solid black;
//     margin: 10px 15px;
//     padding: 5px 10px;
//     border-radius: 10px;
//     background: #96e764;
//     color: black;
//     line-height: 1.2em;
//     font-size: 1.6rem;

//     &:hover {
//         background: #89e74f;
//     }

//     &:active {
//         background: #6dbd3b;
//     }
// }

// <!-- HTML !-->
// <button class="button-36" role="button">Button 36</button>

/* CSS */
.re_moderator_conect_button {
    margin: 10px 15px;
    line-height: 2em;
    background-image: linear-gradient(
        92.88deg,
        #45b545 9.16%,
        #6ecc43 43.89%,
        #3fd753 64.72%
    );
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont,
        "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 4rem;
    padding: 0 1.6rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all 0.5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.re_moderator_conect_button:hover {
    // box-shadow: rgba(68, 205, 63, 0.5) 0 1px 30px;
    background-image: linear-gradient(
        27deg,
        #45b545 9.16%,
        #50ad25 43.89%,
        #1d872b 64.72%
    );
    transition-duration: 0.1s;
}

.re_moderator_conect_button:active {
    // box-shadow: rgba(37, 209, 31, 0.5) 0 1px 30px;
    background-image: linear-gradient(
        27deg,
        #62c70a 9.16%,
        #81cc08 43.89%,
        #23df0a 64.72%
    );
    transition-duration: 0.1s;
}

.re_long_name_style_dialog {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 768px) {
    .re_moderator_conect_button {
        padding: 0 2.6rem;
    }
}

// Style for licence container

.re_licence_box {
    // border: 1px solid #000;
    border-radius: 15px;
    padding: 10px;
    color: #000;
    // width: 80%;
    // margin: 0 auto;
    box-shadow: inset 0px 0px 10px rgb(0 0 0 / 50%);

    .re_licence_header {
        font-size: 80%;
        line-height: 2.2em;
        color: #000;
        font-weight: bold;
        text-align: center;
    }

    .re_licence_body {
        font-size: 1.8rem;
        line-height: 1.2em;

        span {
            font-weight: bold;
        }
    }

    .re_licence_button {
        width: 160px;
        font-size: 2rem;
        line-height: 2em;
        outline: none;
        margin: 0 auto;
        border: 0 none;
        display: block;
        border-radius: 8px;
        border-style: none;
        box-sizing: border-box;
        font-weight: 500;
        height: 4rem;
        padding: 0 1.6rem;
        text-align: center;
        text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
        transition: all 0.5s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .re_licence_button_buy {
        background: #6fcf57;
        color: #fff;

        &:hover {
            background: #4ea837;
        }

        &:active {
            background: #3c9925;
        }
    }
}

// Corusel style for dialog

.carousel-container {
    width: 97%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
    scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 95%;
    flex-shrink: 0;
    flex-grow: 1;
    margin: 0 7px;
    height: min-content;
}

.left-arrow,
.right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    line-height: 1.2em;
    cursor: pointer;
}

.left-arrow {
    left: 5px;
}

.right-arrow {
    right: 10px;
}

/* Style for settings of view service message */

.service_message_add_container .mx_Field{
    margin: 10px 0 30px
}


.service_message_add_power_action_container{
    display: flex;
    align-items: center;
}

.service_message_add_power_action_container .mx_Field{
    margin-right: 5px;
    margin-left: 5px;
}

.re_filter_added_container{
    margin-top: 35px;
    
    .re_filter_edit_container{
        display: flex;
        flex-direction: row
    }

    .mx_Tag{
        margin-top: 10px
    }
}

.re_filter_box_container{
    display: flex;
    flex-direction: column;
    padding: 10px;


    .re_filter_box_header{
        font-size: 1.1rem;
        font-weight: bold;
    }

    .re_filter_box_input{
        margin-top: 10px
    }
}

#re_dialog_button,
#re_dialog_button_cancel{
    width: 100%;
}

#re_dialog_button_cancel{
    background-color: #c5c1c1;
    color: #000;
}

#re_dialog_button:hover{
    background-color: aliceblue;
    transition: 0.2s linear;
}

#re_dialog_button:active{
    background-color: #93badb;
    color: #fff;
    border-color: #000;
    transition: 0.2s linear;
}

#re_dialog_button_cancel:hover{
    background-color: #b7b3b3;
    transition: 0.2s linear;
}

#re_dialog_button_cancel:active{
    background-color: #878585;
    transition: 0.2s linear;
}

.re_main__filter_container{
    margin: 0 10px
}

.re_main_no_edit_filter_container{
    max-width: 250px;
}

.re_value_info_box{
    font-weight: bold;
    margin: 10px 0;
}

.re_value_info_box span:first-child{
    font-size: 1.05rem;
    font-style: italic;
    margin-right: 10px;
    text-decoration: underline;
}

.re_value_info_box span{
    font-size: 1.25rem;
}

.re_delimiter_box{
    color: #514242;
    width: 100%;
    border: 1px solid;
}


.re_filter_added_container .re_delete_filter{
    background-color: #edcece;;
}

.re_button_disabled_click{
    pointer-events:none;
}

.re_no_view_added_filter{
    display: none;
}

.re_hidden_button{
    display: none
}


.re_change_logo_qr{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 0;

    label{
        margin-right: 10px;
    }
}

.re_change_load_logo_qr{
    margin: 10px auto;
    display: block;
}