/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

/* Not actually a component but things shared by settings components */
.mx_UserSettingsDialog,
.mx_RoomSettingsDialog,
.mx_SpaceSettingsDialog,
.mx_SpacePreferencesDialog {
    width: 90vw;
    max-width: 980px;
    /* set the height too since tabbed view scrolls itself. */
    height: 80vh;

    .mx_TabbedView {
        top: 90px;
    }

    .mx_TabbedView .mx_SettingsTab {
        box-sizing: border-box;
        min-width: 580px;
        padding-right: 100px;
        display: flex;
        flex-direction: column;

        /* Put some padding on the bottom to avoid the settings tab from */
        /* colliding harshly with the dialog when scrolled down. */
        padding-bottom: 100px;
    }
}

/* Recosha.chat - add style for dialog windows for admin page*/
.re_AdminDialog, .re_AdminAllDialog {
    width: 150vw;
    max-width: 1400px;
    // set the height too since tabbed view scrolls itself.
    height: 80vh;

    .mx_TabbedView {
        top: 65px;
    }

    .mx_TabbedView .mx_SettingsTab {
        box-sizing: border-box;
        min-width: 580px;
        padding-right: 100px;

        // Put some padding on the bottom to avoid the settings tab from
        // colliding harshly with the dialog when scrolled down.
        padding-bottom: 100px;
    }

    .mx_Dialog_title {
        text-align: center;
        margin-bottom: 24px;
    }
}

