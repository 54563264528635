.re_table {
    text-align: center;
    // font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 3px solid #ddd;
    width: 100%;
}

.re_table td,
.re_table th {
    border: 1px solid #ddd;
    padding: 8px;
}

.re_table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.re_table tr:hover {
    background-color: #ddd;
}

.re_table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4CAF50;
    color: white;
}

.re_label {
    width: 300px;
    border: 2px dashed grey;
    border-radius: 5px;
    display: block;
    padding: 1.2em;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;
}

.re_label input[type=file] {
    outline: 0;
    opacity: 0;
    pointer-events: none;
    user-select: none;
}

.re_labell .re_title {
    color: grey;
    transition: 200ms color
}

.re_label:hover {
    border: 2px solid #000
}

.re_label:hover .re_title {
    color: #000
}

.re_upload_block {
    margin: 12px auto;
    width: min-content;
}


.OurDisableBut button:disabled{
    background-color: #8e7e7e;
    border: 1px solid #8e7e7e;
    opacity: .4;
    color: #f7f2f2;
}

.check-control{
    font-weight: bold;
    font-size: 16px;
}

.loader {
    width: 100%;
    height: 4.8px;
    display: inline-block;
    position: relative;
    background: rgba(255, 255, 255, 0.15);
    overflow: hidden;
    margin-bottom: 15px;
    margin-top: 8px;
  }
  .loader::after {
    content: '';
    width: 192px;
    height: 4.8px;
    background: #A4CF8A;;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
  }
  
  @keyframes animloader {
    0% {
      left: 0;
      transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(0%);
    }
  }



//   report loader
.loader_report {
    width: 100%;
    height: 12px;
    display: inline-block;
    background-color: #F4C91E;
    background-image: linear-gradient(45deg,rgba(0,0,0,1) 25%,transparent 0,transparent 50%,rgba(0,0,0,1) 0,rgba(0,0,0,1) 75%,transparent 0,transparent);
    font-size: 30px;
    background-size: 1em 1em;
    box-sizing: border-box;
    animation: barStripe 1s linear infinite;
  }
  
  @keyframes barStripe {
    0% {
      background-position: 1em 0;
    }
    100% {
      background-position: 0 0;
    }
  }
      