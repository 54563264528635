.re_corp_block_name{
    font-size: 3rem;
    line-height: 1.2em;
    font-weight: bold;
    text-align: center;
}

.corp_list_container{
    height: 60vh;
    overflow: scroll;
    overflow-x: hidden;
    margin: 15px 0;
}

.header_corp_list{
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 1.2em;
}

.corp_card_list_container{
    display: flex;
    flex-wrap: wrap;
}

.corp_card_container{
    width: max-content;
    margin: 10px 15px;
    padding: 15px;
    border: 1px solid #d54040;
    border-radius: 5%;
    background: whitesmoke;
}

.corp_card_header{
    font-size: 2rem;
    line-height: 1.2em;
    font-weight: bold;
    text-align: center;
}

.card_body_delimiter{
    color: #9dc183;
    width: 80%;
    border: 1px solid;
}

.corp_card_body{
    span{
        font-weight: bold;
    }
}

.open_container{
    height: 30px;
    width: 30px;
    background-color: rgb(152 235 152);
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .open_text{
        display: none;
        font-weight: bold;
    }

    .open_img{
        display: inline-block;
        height: 30px;
        width: 30px;
        background: url('$(res)/img/re-icon/right-arrow-svgrepo-com.svg');
        background-repeat: no-repeat;
    }

    &:hover{
        width: 100%;
        border-radius: 5%
    }

    &:hover .open_text{
        display: inline-block;
    }
}