.re_hidden_button {
    text-align: center;
    padding: 5px;
    // margin: 5px;
    margin: 0;
    background: #4e373770;
    font-size: 25px;
    border-radius: 7px 7px 0 0;
    line-height: 1.2em;
    cursor: pointer;
}

.re_hidden_container {
    padding: 5px;
    // margin: 5px;
    margin: 0;
    margin-bottom: 15px;
    background: #ccb2b238;
    border-radius: 0 0 7px 7px;
}

.re_hidden {
    display: none;
}

.re_show {
    display: block;
}

.re_search_element{
    margin-bottom: 10px;

    .re_extended_search{
        width: 300px;
        display: block;
        padding-inline: revert;
        border: 1px solid black;
    }
}


// Style for input elements
.field-wrapper {
    display: block;
    margin: 30px 10px;
    position: relative;
    outline: none;
    width: 35%;
}

.field-wrapper.focused label {
    transition: all .2s linear;
    top: -20px;
    color: blue;
}

.field-wrapper label {
    position: relative;
    color: #2e2f32;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1em;
    position: absolute;
    top: 0px;
    display: inline-block;
    background: #FFF;
    margin: 8px 5px;
    line-height: 1.4em;
    padding: 0 10px;
    transition: all .2s linear;
    text-transform: capitalize;
}

.field-wrapper input,
.field-wrapper textarea {
    padding: 10px;
    width: 100%;
    border: 1px solid #b5b5b5;
    font-weight: 400;
    font-family: Inter, Twemoji, Apple Color Emoji, Segoe UI Emoji, Arial, Helvetica, Sans-Serif, Noto Color Emoji;
    font-size: 1.8rem;
    color: #2e2f32;
}


.fiels-select {
    display: block;
    font-weight: 400;
    font-family: Inter, Twemoji, Apple Color Emoji, Segoe UI Emoji, Arial, Helvetica, Sans-Serif, Noto Color Emoji;
    font-size: 1.8rem;
    color: #2e2f32;
    margin: 17px 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    width: 37%;
    background: #f7f3f3;
    ;
}


.label-name{
    font-weight: 400;
    font-family: Inter,Twemoji,Apple Color Emoji,Segoe UI Emoji,Arial,Helvetica,Sans-Serif,Noto Color Emoji;
    font-size: 2.2rem;
    color: #2e2f32;
}

.field-during{
    padding: 10px;
    width: 95%;
    border: 1px solid #b5b5b5;
    font-weight: 400;
    font-family: Inter, Twemoji, Apple Color Emoji, Segoe UI Emoji, Arial, Helvetica, Sans-Serif, Noto Color Emoji;
    font-size: 1.8rem;
    color: #2e2f32;
}

.field-dayStep{
    width:79%
}

.field-search{
    width: 19%;
}

// Стили для ipunt
/*
    font-weight: 400;
    font-family: Inter,Twemoji,Apple Color Emoji,Segoe UI Emoji,Arial,Helvetica,Sans-Serif,Noto Color Emoji;
    font-size: 1.4rem;
    border: none;
    border-radius: 4px;
    padding: 8px 9px;
    color: #2e2f32;
    background-color: #fff;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
*/

// Стили для label
/*
    transition: font-size .25s ease-out .1s,color .25s ease-out .1s,top .25s ease-out .1s,background-color .25s ease-out .1s;
    color: #2e2f32;
    background-color: transparent;
    font-size: 1.4rem;
    position: absolute;
    left: 0;
    top: 0;
    margin: 7px 8px;
    padding: 2px;
    pointer-events: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
    */



// // Style checkbox

// .container-check {
//     display: block;
//     position: relative;
//     padding-left: 35px;
//     margin-bottom: 12px;
//     cursor: pointer;
//     font-size: 22px;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }

// .container-check input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;
// }

// .checkmark {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 25px;
//     width: 25px;
//     background-color: #e4bfbf;;
// }

// .container-check:hover input~.checkmark {
//     background-color: #ccc;
// }


// .container-check input:checked~.checkmark {
//     background-color: #2196F3;
// }


// .container-check input[type=checkbox][disabled]~.checkmark {
//     background: #7b7575;
// }

// .checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
// }

// .container-check input:checked~.checkmark:after {
//     display: block;
// }

// .container-check .checkmark:after {
//     left: 9px;
//     top: 5px;
//     width: 5px;
//     height: 10px;
//     border: solid white;
//     border-width: 0 3px 3px 0;
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
// }


// during style

.re_group_data{
    width: 48%;
    margin: 5px;
}


// upload button

.re_upload_img_button{
    margin: 10px auto;
    font-size: 2.2rem;
    line-height: 1.5em;
    padding: 5px;
    display: block;
    width: 95%;
    background: rgb(13 189 139 / 60%);
    border: none;
    border-radius: 5px;
    font-weight: 400;
    font-family: Inter, Twemoji, Apple Color Emoji, Segoe UI Emoji, Arial, Helvetica, Sans-Serif, Noto Color Emoji;
    cursor: pointer;
}

.re_upload_img_button:hover{
    background-color: rgb(20 216 160 / 79%);
}

.re_upload_img_button:active{
    background-color: rgb(7 165 120 / 93%);
}

.re_file_name{
    font-weight: 400;
    font-family: Inter, Twemoji, Apple Color Emoji, Segoe UI Emoji, Arial, Helvetica, Sans-Serif, Noto Color Emoji;
    font-size: 1.8rem;
    color: black;
    margin: 0 auto;
    display: block;
    width: max-content;
}

.re_preview_image{
    text-align: center;
    background: white;
    width: max-content;
    padding: 10px;
    margin: 0 auto;
    margin-top: 15px;
}

.re_preview_image p{    
    font-weight: 400;
    font-family: Inter, Twemoji, Apple Color Emoji, Segoe UI Emoji, Arial, Helvetica, Sans-Serif, Noto Color Emoji;
    font-size: 1.8rem;
    color: black;
}


// Right panel

.re_right_panel_form input{
    border: none;
    // border-bottom: 1px solid black;
    width: min-content;
    color: black;
    font-weight: 400;
    font-family: Inter, Twemoji, Apple Color Emoji, Segoe UI Emoji, Arial, Helvetica, Sans-Serif, Noto Color Emoji;
    font-size: 1.4rem;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    padding: 0 10px;
    width: 160px;
}

.re_right_panel_form input[type=checkbox]{
    width: min-content;
}

.re_right_panel_form label{
    color: black;
    font-weight: 400;
    font-family: Inter, Twemoji, Apple Color Emoji, Segoe UI Emoji, Arial, Helvetica, Sans-Serif, Noto Color Emoji;
    font-size: 1.4rem;
}