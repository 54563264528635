// .loader-box {
//     text-align: center;
//     position: absolute;
//     bottom: 40%;
//     background-color: #f5f5f5;
//     left: 50%;
//     line-height: 2em;
// }

.loader-box {
    position: absolute;
    bottom: 40%;
    left: 48%;
    height: 100px;
    background: #f5f5f5;
    text-align: center;
    border-radius: 40px;
    padding: 25px;
}

.loader-box h2 {
    color: #2e2f32;
    font-weight: 400;
    font-size: 1.8rem;
    margin-top: 16px;
    margin-bottom: 16px;
    display: inline-block;
}

// .lds-ring {
//     // display: inline-block;
//     // position: relative;
//     // bottom: 50%;
//     // margin: 10px auto;
//     // width: 80px;
//     // height: 80px;
//     display: inline-block;
//     position: absolute;
//     bottom: 232%;
//     left: 17%;
// }

.lds-ring {
    display: inline-block;
    margin-bottom: 60px;
    margin-right: 30%;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #0dbd8b;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0dbd8b transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// Loader right panel

.loader-right-panel {
    width: 100%;
    height: 4.8px;
    display: inline-block;
    position: relative;
    background: rgba(255, 255, 255, 0.15);
    overflow: hidden;
}

.loader-right-panel::after {
    content: "";
    width: 192px;
    height: 4.8px;
    background: rgb(34, 221, 59);
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
}

@keyframes animloader {
    0% {
        left: 0;
        transform: translateX(-100%);
    }

    100% {
        left: 100%;
        transform: translateX(0%);
    }
}
