.re_static_page_header_block {
    display: flex;
    align-items: center;
    justify-content: center;
    background: hsl(0deg 0% 50% / 20%);
    border-radius: 15px;
    /* width: max-content; */
    padding: 5px;
    margin: 0 15px;
    margin-bottom: 15px;

    img {
        margin: 6px 12px;
        /* background: hsl(0deg 0% 50% / 40%); */
        /* border-radius: 20px; */
        /* padding: 5px; */
        padding-right: 5px;
        border-right: 2px solid hsl(0deg 0% 50% / 40%);
    }
}

.re_static_page_content {
    overflow: auto;
    width: 72vw;
    height: 80vh;
    margin: 0px auto;
    padding: 10px;
}

// Admin Pages

.re_admin_groups_container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
}

.re_admin_group_container {
    // border: 4px double black;
    // width: 400px;
    width: 30vw;
    margin: 12px 10px;
    background: rgba(128, 128, 128, 0.15);
    padding: 5px;
    border-radius: 8px;
    height: min-content;

    h2 {
        background: rgba(128, 128, 128, 0.35);
        padding: 5px;
        border-radius: 8px;
        line-height: 1.2em;
        margin: 0 0 12px;
        font-size: 2.1rem;
        text-align: center;
    }

    .re_admin_button_group {
        background: rgba(128, 128, 128, 0.35);
        padding: 5px;
        border-radius: 8px;
        align-items: center;
    }

    .re_button_container {
        background: rgba(68, 167, 61, 0.7);
        padding: 5px;
        width: 80%;
        margin: 12px auto;
        border-radius: 10px;
        /* margin-bottom: 12px; */

        &:hover {
            background: rgba(68, 167, 61, 0.9);
        }

        .re_button_text {
            font-size: 1.9rem;
            line-height: 1.2em;
            display: block;
            text-align: center;
            cursor: pointer;
        }
    }
}

// General button for our interface
.re_button_general_container {
    max-width: 250px;
    padding: 4px;
    border-radius: 15px;
    margin: 6px 10px;
    cursor: pointer;

    .re_button_general_text {
        display: block;
        text-align: center;
        line-height: 1.2em;
        font-size: 17px;
    }

    .re_button_general_text_intarface {
        margin: 10px 0;
    }

    .re_button_text_action {
        margin: 10px 5px;
    }

    .re_button_icon {
        position: relative;
        width: 40px;
        height: 40px;
    }

    .re_button_icon_interface {
        display: block;
        margin: 0 auto;
        margin-bottom: 5px;
    }

    .re_icon_button_interface {
        &::before {
            background-color: #5c6470;
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: 70%;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .re_personal_portfolio_icon {
        &::before {
            mask-image: url('$(res)/img/re-icon/portfolio-bag-svgrepo-com.svg');
        }
    }

    .re_user_corp_list {
        &::before {
            mask-image: url('$(res)/img/reCorpIcon.svg');
        }
    }
}

.re_button_interface {
    min-width: 250px;
    display: inline-block;
    background: rgba(191, 175, 175, 0.4);

    &:hover {
        background: rgba(191, 175, 175, 0.7);
    }
}

.re_button_action {
    min-width: 180px;
    display: flex;
    background: rgba(99, 209, 63, 0.8);
    justify-content: center;
    align-items: center;

    .re_img_action {
        min-width: 40px;
        width: 40px;
        height: 40px;
        margin: 5px;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .re_img_icon_portfolio {
        background-image: url('$(res)/img/re-icon/portfolio-bag-svgrepo-com.svg');
    }

    &:hover {
        background: rgba(99, 209, 63, 0.9);
    }
}

/* url('$(res)/img/re-icon/right-arrow-svgrepo-com.svg') */
.re_img_icon_corp {
    // corp_button_icon.png
    // corp-create-icon.svg
    background-image: url('$(res)/img/re-icon/corp/corp_button_icon.png');
    // background-image: url(../../../../img/re-icon/portfolio-bag-svgrepo-com.svg);
}

// Стили для шапки
.re_header {
    position: relative;

    .re_header_name {
        text-align: center;
        font-size: 4.8rem;
    }

    .re_header_back_button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        // background: #ebdfdf;
        padding: 4px;
        width: 30px;
        height: 30px;

        &::before {
            background-color: #5c6470;
            mask-image: url('$(res)/img/re-icon/back-arrows-svgrepo-com.svg');
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: 70%;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &:hover::before {
            background-color: #3d424a;
        }
    }
}

.re_content_container {
    display: flex;
    flex-wrap: wrap;
}

.re_container_interface_button {
    margin: 15px 0;
}

// стили для хлебных крошек
/* Стиль списка */
ul.breadcrumb {
    padding: 10px 16px;
    list-style: none;
    border-radius: 15px;
    background-color: #eee;
    width: 95%;
    overflow: auto;
    white-space: nowrap;
    margin: 15px auto;
}

/* Отображение элементов списка бок о бок */
ul.breadcrumb li {
    display: inline;
    font-size: 15px;
}

/* Добавить символ косой черты (/) перед/за каждым элементом списка */
ul.breadcrumb li + li:before {
    padding: 3px;
    color: black;
    content: "/\00a0";
}

/* Добавить цвет для всех ссылок внутри списка */
ul.breadcrumb li a {
    color: #0275d8;
    text-decoration: none;
}

/* Добавить цвет на наведении курсора мыши */
ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
}
