:root, [class*="cpd-theme-"] {
  --cpd-color-text-primary: var(--cpd-color-gray-1400);
  --cpd-color-text-secondary: var(--cpd-color-gray-900);
  --cpd-color-text-disabled: var(--cpd-color-gray-800);
  --cpd-color-text-action-primary: var(--cpd-color-gray-1400);
  --cpd-color-text-action-accent: var(--cpd-color-green-900);
  --cpd-color-text-link-external: var(--cpd-color-blue-900);
  --cpd-color-text-critical-primary: var(--cpd-color-red-900);
  --cpd-color-text-success-primary: var(--cpd-color-green-900);
  --cpd-color-text-info-primary: var(--cpd-color-blue-900);
  --cpd-color-text-on-solid-primary: var(--cpd-color-theme-bg);
  --cpd-color-text-decorative-1: var(--cpd-color-lime-1100);
  --cpd-color-text-decorative-2: var(--cpd-color-cyan-1100);
  --cpd-color-text-decorative-3: var(--cpd-color-fuchsia-1100);
  --cpd-color-text-decorative-4: var(--cpd-color-purple-1100);
  --cpd-color-text-decorative-5: var(--cpd-color-pink-1100);
  --cpd-color-text-decorative-6: var(--cpd-color-orange-1100);
  --cpd-color-bg-subtle-primary: var(--cpd-color-gray-400);
  --cpd-color-bg-subtle-secondary: var(--cpd-color-gray-300);
  --cpd-color-bg-canvas-default: var(--cpd-color-theme-bg);
  --cpd-color-bg-canvas-disabled: var(--cpd-color-gray-200);
  --cpd-color-bg-action-primary-rest: var(--cpd-color-gray-1400);
  --cpd-color-bg-action-primary-hovered: var(--cpd-color-gray-1200);
  --cpd-color-bg-action-primary-pressed: var(--cpd-color-gray-1100);
  --cpd-color-bg-action-primary-disabled: var(--cpd-color-gray-700);
  --cpd-color-bg-action-secondary-rest: var(--cpd-color-theme-bg);
  --cpd-color-bg-action-secondary-hovered: var(--cpd-color-alpha-gray-200);
  --cpd-color-bg-action-secondary-pressed: var(--cpd-color-alpha-gray-300);
  --cpd-color-bg-critical-primary: var(--cpd-color-red-900);
  --cpd-color-bg-critical-hovered: var(--cpd-color-red-1000);
  --cpd-color-bg-critical-subtle: var(--cpd-color-red-200);
  --cpd-color-bg-critical-subtle-hovered: var(--cpd-color-red-300);
  --cpd-color-bg-success-subtle: var(--cpd-color-green-200);
  --cpd-color-bg-info-subtle: var(--cpd-color-blue-200);
  --cpd-color-bg-decorative-1: var(--cpd-color-lime-300);
  --cpd-color-bg-decorative-2: var(--cpd-color-cyan-300);
  --cpd-color-bg-decorative-3: var(--cpd-color-fuchsia-300);
  --cpd-color-bg-decorative-4: var(--cpd-color-purple-300);
  --cpd-color-bg-decorative-5: var(--cpd-color-pink-300);
  --cpd-color-bg-decorative-6: var(--cpd-color-orange-300);
  --cpd-color-bg-accent-rest: var(--cpd-color-green-900);
  --cpd-color-bg-accent-hovered: var(--cpd-color-green-1000);
  --cpd-color-bg-accent-pressed: var(--cpd-color-green-1100);
  --cpd-color-border-disabled: var(--cpd-color-gray-500);
  --cpd-color-border-focused: var(--cpd-color-blue-900);
  --cpd-color-border-interactive-primary: var(--cpd-color-gray-800);
  --cpd-color-border-interactive-secondary: var(--cpd-color-gray-600);
  --cpd-color-border-interactive-hovered: var(--cpd-color-gray-1100);
  --cpd-color-border-critical-primary: var(--cpd-color-red-900);
  --cpd-color-border-critical-hovered: var(--cpd-color-red-1000);
  --cpd-color-border-critical-subtle: var(--cpd-color-red-500);
  --cpd-color-border-success-subtle: var(--cpd-color-green-500);
  --cpd-color-border-info-subtle: var(--cpd-color-blue-500);
  --cpd-color-icon-primary: var(--cpd-color-gray-1400);
  --cpd-color-icon-secondary: var(--cpd-color-gray-900);
  --cpd-color-icon-tertiary: var(--cpd-color-gray-800);
  --cpd-color-icon-quaternary: var(--cpd-color-gray-700);
  --cpd-color-icon-disabled: var(--cpd-color-gray-700);
  --cpd-color-icon-primary-alpha: var(--cpd-color-alpha-gray-1400);
  --cpd-color-icon-secondary-alpha: var(--cpd-color-alpha-gray-900);
  --cpd-color-icon-tertiary-alpha: var(--cpd-color-alpha-gray-800);
  --cpd-color-icon-quaternary-alpha: var(--cpd-color-alpha-gray-700);
  --cpd-color-icon-accent-tertiary: var(--cpd-color-green-800);
  --cpd-color-icon-accent-primary: var(--cpd-color-green-900);
  --cpd-color-icon-critical-primary: var(--cpd-color-red-900);
  --cpd-color-icon-success-primary: var(--cpd-color-green-900);
  --cpd-color-icon-info-primary: var(--cpd-color-blue-900);
  --cpd-color-icon-on-solid-primary: var(--cpd-color-theme-bg);
}
