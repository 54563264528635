// Style checkbox

.container-check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #e4bfbf;
}

.container-check:hover input ~ .checkmark {
    background-color: #ccc;
}

.container-check input:checked ~ .checkmark {
    background-color: #2196f3;
}

.container-check input[type="checkbox"][disabled] ~ .checkmark {
    background: #7b7575;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-check input:checked ~ .checkmark:after {
    display: block;
}

.container-check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

// user manager list
.re_manager_list_id {
    // margin: 25px 15px;
    display: inline-block;
    background: rgba(243, 243, 217, 0.5);
    padding: 5px 40px 5px 11px;
    border-radius: 12px;
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.re_manager_block {
    // display: inline-block;
    margin: 5px 15px;
    display: flex;
    align-items: center;
}

.re_delete_user_manager {
    margin-left: 5px;
    font-weight: bold;
    font-size: 19px;
    cursor: pointer;

    &:hover {
        transform: scale(1.6);
    }
}

.re_user_list_corp {
    cursor: pointer;
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 10px 5px;
    line-height: 1.2em;
    background: rgba(88, 215, 88, 0.35);
    padding: 10px;
    border-radius: 15px;
    text-align: center;

    &:hover {
        background: rgba(20, 223, 20, 0.6);
    }
}

.re_avoid_click_user {
    pointer-events: none;
    text-decoration: line-through;
    font-weight: bold;
    background: rgba(183, 76, 76, 0.35);
}

// Style for button in right panel for invite bots
.re_bot_bridge_button {
    display: block;
    user-select: none;
    font-size: 1.8rem;
    line-height: 1.2em;
    margin: 10px auto;
    padding: 5px 10px;
    // padding-left: 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.re_bot_bridge_button_open_invite {
    width: 85%;
    // background-color: #2bd72b;
    border: 1px solid #000;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    &:hover {
        border: none;
        background-color: #37c937;
        transform: scale(1.1);
    }

    &:active {
        border: none;
        background-color: #26a55f;
    }

    .re_bot_bridge_name_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;

        span:nth-last-child(1) {
            font-size: 4rem;
        }
    }
}

.re_bot_bridge_button_open_invite_active{
    background-color: #2bd72b;
    border: none;
}

.re_bot_bridge_invite_button {
    width: max-content;
    background-color: #38e738;
    color: #fff;

    &:hover {
        background-color: #37c937;
        transform: scale(1.1);
    }

    &:active {
        background-color: #26a55f;
    }
}

.re_bot_bridge_disabled_button{
    width: max-content;
    background-color: #4b504b;
    color: #fff;
}

.re_bot_bridge_invite_button_img {
    background-image: url("$(res)/img/re-icon/integration/icon-telegram.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 15%;
}

.re_bot_bridge_instruction_header {
    text-align: center;
}

.re_invite_container {
    margin: 15px 0;
    padding: 10px 5px;
    background-color: #eddcdc;
    border-radius: 5px;
}

.re_invite_container_no_visible {
    display: none;
}

.re_invite_container_visible {
    display: block;
}
