.re_space_button_position {
    // opacity: 0.5;
    position: relative;
}

.re_RoleButton {
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
    display: inline-block;
}

.re_space_button {
    margin: 0 auto;
    margin-bottom: 12px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: rgba(92, 100, 112, 0.2);
    position: relative;
    /* overwrite mx_RoleButton inline-block */
    display: block !important;
}

/* url('$(res)/img/re-icon/right-arrow-svgrepo-com.svg') */
.advertising_TagTile {
    &::before {
        background-color: #5c6470;
        mask-image: url('$(res)/img/feather-customised/shoutProposals-small.svg');
        mask-position: center;
        mask-repeat: no-repeat;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.re_TagTile {
    &::before {
        background-color: #5c6470;
        mask-image: url('$(res)/img/feather-customised/script.svg');
        mask-position: center;
        mask-repeat: no-repeat;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.admin_TagTile {
    &::before {
        background-color: #5c6470;
        mask-image: url('$(res)/img/feather-customised/admin-page-icon-small.svg');
        mask-position: center;
        mask-repeat: no-repeat;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.profile_TagTile {
    &::before {
        background-color: #5c6470;
        mask-image: url('$(res)/img/re-icon/portfolio-bag-svgrepo-com.svg');
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 70%;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.corp_TagTile {
    &::before {
        background-color: #5c6470;
        mask-image: url('$(res)/img/reCorpIcon.svg');
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 70%;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.re_button_text_icon-container {
    display: flex;
    margin: 0 auto;
    background: rgba(92, 100, 112, 0.2);
    border-radius: 14px;
    padding: 6px;
    cursor: pointer;
    align-items: center;
    // justify-content: center;
    margin-bottom: 12px;
    width: 200px;

    &:hover {
        background: rgba(92, 100, 112, 0.4);
    }

    .re_button_text_icon_text {
        display: inline-block;
        margin: 12px 6px;
    }

    .re_button_text_icon_img {
        min-width: 40px;
        width: 40px;
        height: 40px;
        background: rgba(92, 100, 112, 0.3);
        border-radius: 12px;
    }
}