.modal_container {
    position: fixed;
    z-index: 4000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    position: fixed;
    background: white;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    border-radius: 20px;
    // padding: 24px;

    .button_box {
        display: flex;

        .re_modal_button {
            color: #fff;
            font-weight: 600;
            padding: 7px 18px;
            text-align: center;
            border-radius: 8px;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            font-size: 1.4rem;
            cursor: pointer;
            margin: 10px 15px;
            border: none;
        }

        .re_modal_button_success{
            background-color: #0dbd8b;
        }

        .re_modal_button_success:hover{
            background-color: #15d9a1;
        }

        .re_modal_button_delete{
            background-color: #e31313;
        }
        
        .re_modal_button_delete:hover{
            background-color: #d50b0b;
        }

        .re_modal_button_cancel{
            background-color: #68726f;
        }

        .re_modal_button_cancel:hover{
            background-color: #464e4c;
        }

    }
}

.modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;

    h2 {
        // border-bottom: 1px solid #ccc;
        padding: 1rem;
        margin: 0;
        font-size: 2.5rem;
        line-height: 1.2em;
        max-width: 420px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            font-weight: bold;
        }
    }

    .modal_close_button {
        padding: 1rem;
        margin: 0;
        width: 30px;
        height: 30px;
        cursor: pointer;
        // background-color: #000;
        // mask-image: url('$(res)/img/re-icon/close_icon.svg');
    }

    .modal_close_button:hover {
        transform: rotate(15deg) scale(1.2);
    }
}

.modal_content {
    padding: 1rem;
    // background: #eee;

    font-size: 2rem;
    line-height: 1.2em;
    color: #000;
    font-weight: bold;

    // border-radius: 0 0 20px 20px;

    // .modal_manager_list{
    //     display: flex;
    //     flex-direction: column;
    // }

    .modal_input{
        font-size: 2rem;
        line-height: 1.2em;
        color: #000;
        border: none;
        border-bottom: 1px solid #000;
        margin: 10px 0;
        outline: none
    }

    // .modal_list_column{
    //     width: max-content;
    //     margin: 10px 15px;
    // }

    p span {
        color: #000;
        font-weight: 100;
    }

    .modal_manager_list .manager_list_row {
        padding: 0 2rem;
        font-weight: 100;
        margin: 10px 0
    }

    .manager_list_row a{
        color: #000;
    }

    .manager_list_row::before {
        content: "-";
        padding: 0 5px;
    }
}

.modal_content_edit{
    display: flex;
    flex-direction: column;
    height: 60vh;
    overflow: scroll;
    overflow-x: hidden;
    margin: 15px 0;
}
